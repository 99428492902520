.switch {
  width: 52px;
  height: 32px;
  border-radius: 100px;
  background-color: #e6e0e9;
  border: 2px solid #79747e;
  position: relative;
  cursor: pointer;
}

.switch.active {
  background-color: #65558f;
  border: 2px solid #65558f;
}

.switchButton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 4px;
  background-color: #79747e;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switchButton.active {
  left: 20px;
  background-color: white;
}
